@keyframes bgcolor {
    0% {
        background-color: #343a40
    }
    30% {
        background-color: #007bff
    }
    60% {
        background-color: #343a40
    }
    90% {
        background-color: #007bff
    }
    100% {
        background-color: #343a40
    }
}

.dynamicBackground {
    -webkit-animation: bgcolor 20s infinite;
    animation: bgcolor 7s infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}