.bubble {
    --r: 10px;
    /* the radius */
    --t: 20px;
    /* the size of the tail */
    max-width: 300px;
    padding: calc(2*var(--r)/3);
    -webkit-mask: radial-gradient(var(--t) at var(--_d) 0, #0000 98%, #000 102%) var(--_d) 100%/calc(100% - var(--r)) var(--t) no-repeat, conic-gradient(at var(--r) var(--r), #000 75%, #0000 0) calc(var(--r)/-2) calc(var(--r)/-2) padding-box, radial-gradient(50% 50%, #000 98%, #0000 101%) 0 0/var(--r) var(--r) space padding-box;
    color: #fff;
}

.leftFb {
    background: #0078ff;
    color: #fff;
    --_d: 0%;
    border-left: var(--t) solid #0000;
    margin-right: var(--t);
    place-self: start;
}

.rightFb {
    background: #dfe3ee;
    color: #000;
    --_d: 100%;
    border-right: var(--t) solid #0000;
    margin-left: var(--t);
    place-self: end;
}

.leftInsta {
    background: #C13584;
    color: #fff;
    --_d: 0%;
    border-left: var(--t) solid #0000;
    margin-right: var(--t);
    place-self: start;
}

.rightInsta {
    background: #dfe3ee;
    color: #000;
    --_d: 100%;
    border-right: var(--t) solid #0000;
    margin-left: var(--t);
    place-self: end;
}

.leftSms {
    background: #ffe79a;
    color: #000;
    --_d: 0%;
    border-left: var(--t) solid #0000;
    margin-right: var(--t);
    place-self: start;
}

.rightSms {
    background: #5e87b8;
    color: #fff;
    --_d: 100%;
    border-right: var(--t) solid #0000;
    margin-left: var(--t);
    place-self: end;
}

/* ****************** */

* {
    margin: 0px;
    padding: 0px;
}

.box1 {
    z-index: 2;
    width: 300px;
    margin: 8px;
    border-radius: 8px;
    background: #f8f9fa;
    color: #fff;
    padding: 20px;
    text-align: left;
    position: relative;
}

.box2 {
    z-index: 2;
    width: 300px;
    margin: 25px;
    border-radius: 8px;
    background: #dcf8c6;
    color: #fff;
    padding: 20px;
    text-align: left;
    position: relative;
}

.sb1:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 6px solid transparent;
    border-right: 6px solid #f8f9fa;
    border-top: 6px solid #f8f9fa;
    border-bottom: 6px solid transparent;
    left: -7px;
    top: 0px;
}

.sb2:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 6px solid #dcf8c6;
    border-right: 6px solid transparent;
    border-top: 6px solid #dcf8c6;
    border-bottom: 6px solid transparent;
    right: -7px;
    top: 0px;
}