/* .metismenu {
    position: fixed;
    width: 280px;
    bottom: 0;
    left: 0;
    top: 0;
} */

/* .metismenu {
    width: 100%;
    bottom: 0;
    left: 0;
    top: 0;
} */

.sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    font-size: 20px;
    background-color: #202124;
    overflow-x: hidden;
    transition: 0.4s;
    padding-top: 0px;
    /* overflow-y: auto; */
}

.sidebar a {
    /* padding: 8px 8px 8px 32px; */
    text-decoration: none;
    font-size: 14px;
    color: #b7b3b3;
    display: block;
    transition: 0.3s;
    background-color: #202124;
    font-family: 'Abel', sans-serif;
}

.sidebar a:hover {
    color: #f1f1f1;
}

.sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

.openbtn {
    font-size: 15px;
    cursor: pointer;
    /* background-color: #111; */
    color: white;
    /* padding: 10px 15px; */
    border: none;
    /* height: 100%;
    width: 100%; */
}

.openbtn:hover {
    background-color: #2e2e2e;
}

#main {
    transition: margin-left .5s;
    /* padding: 16px; */
}

.iconStyle1 {
    font-size: 20px;
    /* color: #7bec0a; */
    margin-right: 0px;
    max-width: 45px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */

@media screen and (max-height: 450px) {
    .sidebar {
        padding-top: 15px;
    }
    .sidebar a {
        font-size: 18px;
    }
}